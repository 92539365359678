import React, { useRef } from 'react';
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import team1 from '../../assets/images/team1.png';
import team2 from '../../assets/images/team2.png';
import team3 from '../../assets/images/team3.png';
import team4 from '../../assets/images/team4.png';
import team5 from '../../assets/images/team5.png';
import team6 from '../../assets/images/team6.png';

function TeamHomeOne() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="appie-team-area pt-100 mb-20 pb-100" id="team">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Our NFT Developers</h3>
                        </div>
                    </div>
                </div>
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <div className="container">
                            <div className="row appie-showcase-slider">
                                <div className="col-lg-12">
                                    <Slider ref={sliderRef} {...settings}>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="200ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team1} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Olatunji Uthman</h5>
                                                            <span>3d character Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="200ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team2} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Taha Imran</h5>
                                                            <span>Character Animator</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team3} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Saheed Hammed</h5>
                                                            <span>Environment and Concept Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team4} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Ann Ben</h5>
                                                            <span>2d graphics Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">

                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team5} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Adebayo Taofeeq</h5>
                                                            <span>NFT Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team6} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Abdulroqeeb Busari</h5>
                                                            <span>Collection Generator</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>

            </section>
            {/* <section className="appie-team-area pt-100 mb-20 pb-100" id="team">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Our Game Developers</h3>
                        </div>
                    </div>
                </div>
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <div className="container">
                            <div className="row appie-showcase-slider">
                                <div className="col-lg-12">
                                    <Slider ref={sliderRef} {...settings}>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="200ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team1} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Olatunji Uthman</h5>
                                                            <span>3d character Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="200ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team2} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Taha Imran</h5>
                                                            <span>Character Animator</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team3} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Saheed Hammed</h5>
                                                            <span>Environment and Concept Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team4} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Ann Ben</h5>
                                                            <span>2d graphics Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">

                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team5} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Adebayo Taofeeq</h5>
                                                            <span>NFT Artist</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <div
                                                        className="appie-team-item glow mt-30 wow animated fadeInUp"
                                                        data-wow-duration="2000ms"
                                                        data-wow-delay="400ms"
                                                    >
                                                        <div className="thumb">
                                                            <img src={team6} alt="" />
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-facebook-f" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fab fa-twitter" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="content text-center">
                                                            <h5 className="title">Abdulroqeeb Busari</h5>
                                                            <span>Collection Generator</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>

            </section> */}
        </>
    );
}

export default TeamHomeOne;
