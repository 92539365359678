import React from 'react';
import { Link } from 'react-router-dom';
import Timer from '../Helper/Timer';
import SoulVideo from "../../assets/videos/soulVideo.mp4"

function HeroHomeOne() {

    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-md-1">
                            <div className="appie-hero-content " data-aos="fade-right" >
                                <h1 className="appie-title" >
                                    The Lost Soul Island Collection
                                </h1>
                                <p>
                                    An exclusive NFT collection of 10,000 NFT's based on "The Island of Dr. Moreau" movie. Dr. Winter has injected humans with animal DNA. The super rarest NFT's morph from one character into another.Locals on the mainland call the island "Isla del alma perdida" (Lost Soul Island). Rumors of manbeasts and unhuman sounds circulate for good reason. It all started when respected Dr. Winter lost his family due to violence. Determined to rid the world of violence, he moved to the island and decided to inject humans with animal DNA, hoping to create a more peaceful, new species to populate the world. He paid an unscrupulous sea captain for a supply of victims.
                                </p>
                                <ul>
                                    <li>
                                        <Link
                                            className="main-btn btn-gradient hvr-pulse-grow"
                                            to={{ pathname: "https://discord.gg/yvKDkuN8T5" }} target="_blank" >Join the whitelist!</Link>

                                        <div className='mt-4'>
                                            <Timer />
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-md-2 order-1">
                            <div className="appie-hero-thumb" data-aos="fade-left">
                                <div
                                    className="thumb wow animated fadeInUp bannerImg"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <video width="400" height="420" autoPlay loop>
                                        <source src={SoulVideo} type="video/mp4" />

                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
