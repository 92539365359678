import React from 'react';

function Roadmap() {
    return (
        <>
            <section className="appie-team-area pt-100 mt-50  pb-100" id="roadmap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Roadmap</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="timeline">
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">City of Life Metaverse</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">October</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Full game launch</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">July</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Launching Lost Soul Island game beta</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">June</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Launching NFT's</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">February</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Roadmap;
