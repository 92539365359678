import React, { useRef } from 'react';
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import nft8 from '../../assets/images/nft8.png';
import nft9 from '../../assets/images/nft9.png';
import nft10 from '../../assets/images/nft10.png';
import nft11 from '../../assets/images/nft11.png';
import nft12 from '../../assets/images/nft12.png';
import nft13 from '../../assets/images/nft13.jpg';


function ShowCaseHomeThree() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="">
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <div className="container">
                            <div className="row appie-showcase-slider">
                                <div className="col-lg-12">
                                    <Slider ref={sliderRef} {...settings}>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img src={nft8} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img src={nft9} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img src={nft10} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img src={nft11} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img src={nft12} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img src={nft13} alt="" height='370' />
                                                </a>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </section>
        </>
    );
}

export default ShowCaseHomeThree;
