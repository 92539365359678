import React from 'react';
import { Link } from 'react-router-dom';

function ServicesHomeOne({ className }) {
    return (
        <section className={`serviceHome pt-90 pb-100 ${className}`} id="story">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                The Lost Soul Island
                            </h3>
                            <p>After years of abuse, the man animals decided to revolt and fight for the serum to regain their human form. The VR game is the uprising, they must fight other manimals and compete with other uprisers in their search for the serums. Ownership of an NFT gives access to the game, and successful completion of the game gives access to the City of Life metaverse. Come and join the revolt and reap the rewards!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        {/* <button type="button" className="main-btn btn-gradient hvr-pulse-grow">
                            Get Early Access!
                        </button> */}
                        <div className="">
                            <Link
                                className="main-btn btn-gradient hvr-pulse-grow"
                                to={{ pathname: "https://discord.gg/yvKDkuN8T5" }} target="_blank" >Get Early Access</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
